import React from 'react';
import { Link } from 'react-router-dom';

import './HomePage.css';
import techImage from '../assets/tech.jpeg'; // Assume you have an image named tech.jpg
import financeImage from '../assets/finance.jpeg'; // Assume you have an image named finance.jpg

const HomePage = () => {
  return (
    <div className="homepage">
      <section className="content">
        <div className="about">
          <p>
            Empowering clients with state-of-the-art SaaS platforms and comprehensive education on technology and finance. Our mission is to bridge the gap between innovation and practicality, delivering robust software solutions that streamline business operations and enhance productivity.
          </p>
        </div>
        <div className="topics">
          <Link to="/services" className='topic-link'>
            <div className="topic">
              <img src={techImage} alt="Technology" />
              <h2>Custom websites</h2>
              <p>Imagine and build an internet space.</p>
            </div>
          </Link>
          <Link to="/services/#full-saas" className="topic-link">
            <div className="topic">
              <img src={financeImage} alt="Finance" />
              <h2>Full SaaS</h2>
              <p>Improve your business productivity. </p>
            </div>
          </Link>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
