import React from 'react';
import './ContactUs.css';

const ContactUs = () => {
  return (
    <div className="contact-us">
      <section className="contact-info">
        <h2>Get in Touch</h2>
        <div className="contact-content">
          <p className="contact-text">
            Have questions? Want to discuss your next project? Drop us a line, and let's make magic happen!
          </p>
          <div className="contact-details">
            <p>Email: <a href="mailto:g.nunez.2803@gmail.com">g.nunez.2803@gmail.com</a></p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactUs;
