import React from 'react';
import './LetsLearn.css';
import techCourseImage from '../assets/tech.jpeg'; // Assume you have an image named tech-course.jpg
import financeCourseImage from '../assets/finance.jpeg'; // Assume you have an image named finance-course.jpg

const LetsLearn = () => {
  return (
    <div className="lets-learn-page">
      <header className="header">
        <h1>Let's Learn</h1>
        <p>Empowering you with knowledge in tech and finance.</p>
      </header>
      <section className="courses">
        <div className="course">
          <img src={techCourseImage} alt="Tech Course" />
          <h2>Technology Course</h2>
          <p>Explore the latest advancements in technology, including AI, software development, and more. Stay ahead of the curve with our in-depth tech courses designed for all skill levels.</p>
        </div>
        <div className="course">
          <img src={financeCourseImage} alt="Finance Course" />
          <h2>Finance Course</h2>
          <p>Gain insights into the world of finance, from cryptocurrency to financial planning. Our finance courses provide you with the knowledge and tools to make informed financial decisions.</p>
        </div>
      </section>
    </div>
  );
};

export default LetsLearn;
