import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import HomePage from './components/HomePage';
import CustomWebsites from './components/CustomWebsites';
import CustomSaaS from './components/CustomSaaS';
import LetsLearn from './components/LetsLearn';
import ContactUs from './components/ContactUs';
import Services from './components/Services';
import ScrollToTop from './helpers/ScrollToTop';


const App = () => {
  return (
    <Router>
      <ScrollToTop/>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/services" element={<Services/>} />
        <Route path="/services/custom-websites" element={<CustomWebsites />} />
        <Route path="/services/custom-saas" element={<CustomSaaS />} />
        <Route path="/lets-learn" element={<LetsLearn />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default App;