import React from 'react';
import { useNavigate } from 'react-router-dom';
import './CustomWebsites.css';
import { FaMobileAlt, FaPaintBrush, FaUserFriends, FaRocket } from 'react-icons/fa';

const CustomWebsites = () => {
  const navigate = useNavigate();

  const handleGetInTouch = () => {
    navigate('/contact');
  };

  return (
    <div className="custom-websites">
      <header className="cw-header">
        <div className="header-content">
          <h1>Custom Websites</h1>
          <p>Tailored to your needs.</p>
        </div>
      </header>
      
      <section className="features">
        <div className="feature-list">
          <div className="feature">
            <FaMobileAlt className="icon" />
            <h3>Responsive Design</h3>
            <p>Our websites look great on any device, from smartphones to desktops.</p>
          </div>
          <div className="feature">
            <FaPaintBrush className="icon" />
            <h3>Custom Design</h3>
            <p>Unique and engaging designs tailored to your brand and vision.</p>
          </div>
          <div className="feature">
            <FaUserFriends className="icon" />
            <h3>User-Friendly</h3>
            <p>Easy-to-navigate websites that enhance user experience and engagement.</p>
          </div>
          <div className="feature">
            <FaRocket className="icon" />
            <h3>Fast Performance</h3>
            <p>Optimized for speed and performance to keep your visitors engaged.</p>
          </div>
        </div>
      </section>
      
      <section className="cta">
        <h2>Ready to Start?</h2>
        <p>Contact us today to discuss your custom website needs and get a free quote!</p>
        <button className="cta-button" onClick={handleGetInTouch}>Get in Touch</button>
      </section>
    </div>
  );
};

export default CustomWebsites;
