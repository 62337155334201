import React, { useEffect } from 'react';

import CustomSaaS from "./CustomSaaS";
import CustomWebsites from "./CustomWebsites";
import { Element, scroller } from 'react-scroll';
import { useLocation } from 'react-router-dom';

const Services = () => {
    const location = useLocation();

    useEffect(() => {
        const hash = location.hash.replace('#', '');
        if (hash) {
          scroller.scrollTo(hash, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
          });
        }
      }, [location]);

    return (
        <div className="s-header">
            <Element name="custom-websites">
                <CustomWebsites />
            </Element>
            <Element name="full-saas">
                <CustomSaaS />
            </Element>
        </div>
    );
};

export default Services;