import React from 'react';
import './CustomSaaS.css';
// Importing icons from react-icons (you need to install react-icons package)
import { FaDollarSign, FaCogs, FaEdit, FaSyncAlt } from 'react-icons/fa';
import scalability from '../assets/scalability.png';
import security from '../assets/security.jpeg';
import support from '../assets/support.jpeg';

const CustomSaaS = () => {
  return (
    <div className="custom-saas">
      <header className="cs-header">
        <h1>SaaS Solutions</h1>
        <p>Designed to scale with your business.</p>
      </header>
      <section className="features">
        <h2>Features</h2>
        <div className="feature-container">
          <div className="feature-description">
            <p>Quick, affordable, and durable technical solutions to drive your company.</p>
          </div>
          <div className="feature-list">
            <div className="feature">
              <h3>Scalability</h3>
              <img src={scalability} alt="Scalability" />
              <p>Our solutions grow with your business, ensuring you never outgrow our services.</p>
            </div>
            <div className="feature">
              <h3>Security</h3>
              <img src={security} alt="Security" />
              <p>Top-notch security measures to protect your data and give you peace of mind.</p>
            </div>
            <div className="feature">
              <h3>Support</h3>
              <img src={support} alt="Support" />
              <p>24/7 support to assist you whenever you need it.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="benefits">
        <h2>Benefits</h2>
        <div className="benefit-list">
          <div className="benefit">
            <FaDollarSign className="icon" />
            <p>Cost-effective</p>
          </div>
          <div className="benefit">
            <FaCogs className="icon" />
            <p>Easy to implement</p>
          </div>
          <div className="benefit">
            <FaEdit className="icon" />
            <p>Customizable</p>
          </div>
          <div className="benefit">
            <FaSyncAlt className="icon" />
            <p>Regular updates</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CustomSaaS;